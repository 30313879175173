.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px 10%;
}

.card_button {
  border: 0;
  padding: 0;
  margin: 0;
  background: 0;
}

.card {
  display: inline-block;
}

.title {
  margin: 0;
}

.shortDesc {
  font-size: 1rem;
  text-align: left;
}

.fullSiteContainer {
  position: relative;
  background: var(--background-primary);
  padding: 40px;
}

.carouselContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  /* overflow: hidden; */
}

.closeBtn {
  position: absolute;
  top: 51px;
  right: 40px;
  z-index: 2;
}
