.container {
  max-width: 650px;
  border-radius: 6px;
  padding: 20px;
  margin: 0 auto;
  /* box-shadow: 2px 2px 4px #555; */
  /* border: 1px solid darkgray; */
}

.pic {
  height: auto;
  width: 50%;
  /* float: left; */
  /* background-color: var(--primary-color); */
  /* border-radius: 6%; */
  /* border: 6px solid white; */
  overflow: hidden;
  margin: 0 auto;
  @media (max-width: 650px) {
    height: 200px;
    width: 200px;
    float: none;
    margin: auto;
  }
}

.pic > img {
  height: 100%;
  width: 100%;
  @media (max-width: 650px) {
  }
}
