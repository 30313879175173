.textLink {
  color: var(--link-color);
  text-decoration: none;
}

.aboveTheFold {
  display: grid;
  grid-template-areas: "text image";
  grid-template-columns: 60% 40%;
  /* grid-template rows */
}

.aboveTheFold_text {
  grid-area: text;
  line-height: 1.25;
  font-size: 2.5rem;
}

.aboveTheFold_image {
  grid-area: image;
  height: 100%;
  width: auto;
}
