:root {
  --primary-color: #ff6d04;
  --secondary-color: #0488ff;
  --background-primary: #323232;
  --background-header: #545454;
  --text-color: #fff;
  --link-color: #0488ff;
}

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: var(--text-color);
}

a {
  color: var(--link-color);
  text-decoration: none;
}
