.center {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.heading {
  margin-top: 0;
}

.contact_form {
  display: flex;
  flex-direction: column;
}
.spacer {
  margin-top: 20px;
}
