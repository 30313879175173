.nav {
  display: flex;
  flex-direction: column;
}

.link_row {
  display: flex;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
  flex-grow: 2;
  padding: 10px 0px 10px 30px;
  color: white;
}
.link_row:hover {
  background: #ff6d0433;
}
.link_row[data-activelink="active"] {
  background: #ff6d04;
}

.nav_icon {
  height: 1.1rem;
  width: 1.1rem;
}

.nav_text {
  line-height: 1rem;
  padding: 0;
  margin-bottom: -8px;
  margin-left: 5px;
}
